import React from 'react'
import tw from "twin.macro";

export const Heading = tw.h2`w-full text-2xl md:text-5xl font-black tracking-wide text-center xs:text-2xl`

export const SubHeading = ({children}) => <h5 className="font-bold my-4 text-gradient text-center text-xl sm:text-sm">{children}</h5>
export const SubHeadingWhite = ({children}) => <h5 className="font-bold my-4 text-white text-center text-xl sm:text-sm">{children}</h5>

export const Description = tw.p`w-full text-center mt-4 text-sm md:text-base lg:text-lg 
font-medium leading-relaxed text-secondary-100 max-w-xl`;

export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-left`

export const AltHeading = tw(SectionHeading)`text-gray-900 mb-10 mt-8`