import styled from "@emotion/styled"
import * as React from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import { CustomLink } from "../components/Common/Button"
import { Description, Heading } from "../components/Common/Headings"
import Seo from "../components/SEO"

const Container = styled.div`
  ${tw`py-56 flex flex-col items-center justify-center`}
  * {
    ${tw`m-6`}
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Seo title={t("404_HEADING")} />
      <Heading>{t("404_HEADING")}</Heading>
      <Description>{t("404_SUBHEADING")}</Description>
      <CustomLink to="">{t("404_BUTTON_TEXT")}</CustomLink>
    </Container>
  )
}

export default NotFoundPage
